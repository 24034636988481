// @ts-check
import { createSlice } from "@reduxjs/toolkit";
import { PRODUCT } from "storeseo-enums/analysisEntityTypes";

const initialState = {
  type: PRODUCT,
  metaTitle: "",
  metaDescription: "",
  tags: null,
  creating: false,
  isKept: {
    metaTitle: false,
    metaDescription: false,
    tags: false,
  },
};

export const aiContentSlice = createSlice({
  name: "aiContent",
  initialState: { ...initialState },
  reducers: {
    setMetaTitle: (state, action) => {
      state.metaTitle = action.payload;
    },
    setMetaDesc: (state, action) => {
      state.metaDescription = action.payload;
    },
    setTags: (state, action) => {
      state.tags = action.payload;
    },
    setCratingState: (state, action) => {
      state.creating = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    setIsKept: (state, action) => {
      state.isKept = {
        ...state.isKept,
        ...action.payload,
      };
    },

    resetAiContent: (state, action) => {
      return { ...initialState };
    },
  },
});

export const { setMetaTitle, setMetaDesc, setTags, setCratingState, setType, setIsKept, resetAiContent } =
  aiContentSlice.actions;
export default aiContentSlice.reducer;
