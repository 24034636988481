import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/User";
import onboardedReducer from "./features/Onboarded";
import productCountReducer from "./features/ProductCount";
import campaignReducer from "./features/Campaign";
import productSyncReducer from "./features/ProductSync";
import blogSyncReducer from "./features/BlogSync";
import notificationsReducer from "./features/Notifications";
import pageSyncReducer from "./features/PageSync";
import OptimizationTaskReducer from "./features/OptimizationTask";
import OnboardStepReducer from "./features/OnboardStep";
import HiddenBannerReducer from "./features/HiddenBanner";
import AiContentReducer from "./features/AiContent";
import collectionCountReducer from "./features/CollectionCount.js";
import CollectionSyncReducer from "./features/CollectionSync.js";

export default configureStore({
  reducer: {
    user: userReducer,
    onboarded: onboardedReducer,
    onboardStep: OnboardStepReducer,
    productCount: productCountReducer,
    campaign: campaignReducer,
    productSync: productSyncReducer,
    blogSync: blogSyncReducer,
    pageSync: pageSyncReducer,
    notifications: notificationsReducer,
    optimizationTask: OptimizationTaskReducer,
    hiddenBanner: HiddenBannerReducer,
    collectionCount: collectionCountReducer,
    collectionSync: CollectionSyncReducer,
    aiContent: AiContentReducer,
  },
});
