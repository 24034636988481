import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  numOfUnreadNotifications: 0,
  latestNotificationsLoaded: false,
  allNotificationsLoaded: false,
  /** @type {import("../../../jsDocTypes").NotificationDetails[]} */
  list: [],
};

export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNewNotification: (state, action) => {
      state.numOfUnreadNotifications += 1;
      state.list.unshift(action.payload);
    },

    setInitialNotifications: (state, action) => {
      state.list = action.payload;
      state.latestNotificationsLoaded = true;
    },

    setAllNotifications: (state, action) => {
      state.list = action.payload;
      state.allNotificationsLoaded = true;
    },

    readNotification: (state, action) => {
      const n = state.list.find((n) => n.id === action.payload);
      if (n.is_read) return;

      n.is_read = true;
      state.numOfUnreadNotifications -= 1;
    },

    readAllNotifications: (state, action) => {
      state.list.forEach((n) => (n.is_read = true));

      state.numOfUnreadNotifications = 0;
    },

    unreadNotifications: (state, action) => {
      const n = state.list.find((n) => n.id === action.payload);
      n.is_read = false;

      state.numOfUnreadNotifications += 1;
    },

    setUnreadNotificationsCount: (state, action) => {
      state.numOfUnreadNotifications = action.payload;
    },
  },
});

export const {
  addNewNotification,
  setAllNotifications,
  setInitialNotifications,
  readNotification,
  unreadNotifications,
  readAllNotifications,
  setUnreadNotificationsCount,
} = notificationSlice.actions;

export default notificationSlice.reducer;
